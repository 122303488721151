<template>
  <div style="padding-top: 5px">
    <StaffTable />
    <StaffPagination />
  </div>
</template>

<script>
import StaffTable from './StaffTable';
import StaffPagination from './StaffPagination';
export default {
  name: 'StaffContainer',
  components: { StaffPagination, StaffTable },
};
</script>
