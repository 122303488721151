<template>
  <div>
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'员工管理'"
      :icon="'el-icon-s-custom'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="staffData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="staffData[item.prop]" />
          </el-form-item>
          <el-form-item required label-width="110px" label="部门名称">
            <el-select v-model="insertID['deptID']" placeholder="请选择">
              <el-option
                v-for="item in dept_store.dept_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item required label-width="110px" label="职务">
            <el-select v-model="insertID['positionID']" placeholder="请选择">
              <el-option
                v-for="item in position_store.position_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="staff_store.staff_info"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
          <el-form-item required label-width="110px" label="部门名称">
            <el-select v-model="updateID['deptID']" placeholder="请选择">
              <el-option
                v-for="item in dept_store.dept_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item required label-width="110px" label="职务">
            <el-select v-model="updateID['positionID']" placeholder="请选择">
              <el-option
                v-for="item in position_store.position_info"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '../common/Table';
import TableHeader from '../common/TableHeader';
import FilterHeader from '../common/FilterHeader';
import { mapState } from 'vuex';
import {
  DELETE_STAFF_INFO,
  GET_DEPT_INFO,
  GET_POSITION_INFO,
  GET_STAFF_INFO,
  INSERT_STAFF_INFO,
  UPDATE_STAFF_INFO,
} from '../../store/type';
export default {
  name: 'StaffTable',
  components: {
    FilterHeader,
    TableHeader,
    Table,
  },
  data() {
    return {
      showDelBtn: false,
      rowData: {},
      // 对象嵌套没找到更好的解决方案，所以嵌套的对象只能手动写...
      columnConfig: [
        { prop: 'name', label: '员工姓名' },
        { prop: 'sex', label: '员工性别' },
        { prop: 'age', label: '员工年龄' },
        { prop: 'createdAt', label: '创建时间', show: false },
        { prop: 'updatedAt', label: '更新时间', show: false },
        { prop: 'dept.name', label: '部门名称', show: false },
        { prop: 'position.name', label: '职务', show: false },
        { prop: 'dept.id', label: '部门ID', show: false },
        { prop: 'position.id', label: '职务ID', show: false },
      ],
      staffData: {},
      delData: [],
      // select没办法绑定多维对象，所以这里单独用一维对象存放部门和职位id
      insertID: {},
      updateID: {},
    };
  },
  computed: {
    ...mapState({
      staff_store: state => state.staff_store,
      dept_store: state => state.dept_store,
      position_store: state => state.position_store,
    }),
  },
  methods: {
    // 在执行插入，更新时因为接口给的字段是部门/职位id，但是又不能让用户输入部门id，
    // 所以要先判断下部门职位是否存在
    // 当然一个更有效的做法是直接用一个选择器，这样就不会出现非法情况了，也就不用判断是否非法了
    formStaffInfo(data, id) {
      return {
        name: data.name,
        age: data.age,
        sex: data.sex,
        dept: id.deptID,
        position: id.positionID,
        id: data.id,
      };
    },
    insertCustomer() {
      // 这里先把其它字段的合法性判断放一放
      if (this.checkSelect(this.insertID)) {
        this.$message({
          duration: 600,
          type: 'error',
          message: '请检查您的选择是否正确！',
        });
        return;
      }
      this.$store.dispatch(
        INSERT_STAFF_INFO,
        this.formStaffInfo(this.staffData, this.insertID),
      );
      this.$message({
        type: 'success',
        message: '新建成功!',
        duration: 1000,
      });
      setTimeout(() => window.location.reload(), 1000);
    },
    deleteTableData() {
      this.$store.dispatch(DELETE_STAFF_INFO, this.delData);
      this.$message({
        duration: 1000,
        message: '删除成功！',
        type: 'success',
      });
      setTimeout(() => window.location.reload(), 1000);
    },
    getDelData(data) {
      this.delData = data;
      this.showDelBtn = this.delData.length > 0;
    },
    editData(row) {
      this.rowData = row;
    },
    updateData() {
      if (this.checkSelect(this.updateID)) {
        this.$message({
          duration: 600,
          type: 'error',
          message: '请检查您的选择是否正确！',
        });
        return;
      }
      this.$store.dispatch(
        UPDATE_STAFF_INFO,
        this.formStaffInfo(this.rowData, this.updateID),
      );
      this.$message({
        duration: 1000,
        message: '更新成功！',
        type: 'success',
      });
      setTimeout(() => window.location.reload(), 1000);
    },
    checkSelect(s) {
      return !s['deptID'] || !s['positionID'];
    },
  },
  created() {
    // 防止表格渲染二维对象时出错
    this.rowData['dept'] = {};
    this.rowData['position'] = {};
    this.staffData['dept'] = {};
    this.staffData['position'] = {};
    this.$store.dispatch(GET_DEPT_INFO);
    this.$store.dispatch(GET_POSITION_INFO);
    this.$store.dispatch(GET_STAFF_INFO, {
      url: '/employees',
      method: 'get',
    });
  },
};
</script>
<style scoped>
.el-select {
  width: 100% !important;
}
</style>
